<template>
    <div class="box">
      <div class="top"></div>
      <div>
        <router-link to='/one' class="colors">
          <div class="divb">内置版定位及定位到国外</div>
        </router-link>
        <div class="diva"></div>
        <router-link to='/two' class="colors">
          <div class="divb">内置版分身路线模拟操作</div>
        </router-link>
        <div class="diva"></div>
        <router-link to='/three' class="colors">
          <div class="divb">独立版定位及定位到国外</div>
        </router-link>
        <div class="diva"></div>
        <router-link to='/wentia' class="colors">
          <div class="divb">独立版本更新注意事项</div>
        </router-link>
        <div class="diva"></div>
<!--        <router-link to='/one' class="colors">
          <div class="divb">如何找回和修改密码</div>
        </router-link>
        <div class="diva"></div>-->
      </div>
    </div>
</template>

<script>
export default {
  components: { },
  props: {},
  data() {
    return {
      isLoading: true,
    };
  },
  watch: {},
  computed: {},
  methods: {
  },
  created() {
  },
  mounted() {},
};
</script>
<style>
.box {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.box ul{
  height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box ul li{
  display:inline;
  list-style-type:none;
  padding-left: 0.4rem;
  height: 30vh;
  border-bottom: 1px solid rgba(7,17,27,0.1);
}
.diva {
  border-bottom: 1px solid #0000001a;
}
.divb{
  height: 57px;
  font-size: 17px;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  justify-content: flex-start;
  align-items: center;
}
.colors{
  color: black;
}
.top{
  height: 1vh;
}
</style>